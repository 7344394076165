const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const jwt = () => localStorage.getItem('jwt');

export const getDashboardUsers = (options = {}) =>
    fetch(`${REACT_APP_API_URL}/dashboard-user${options.isSimple ? '?is_simple=1' : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const getDashboardUser = (dashboardUserId) =>
    fetch(`${REACT_APP_API_URL}/dashboard-user/${dashboardUserId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const createDashboardUser = (dashboardUser) =>
    fetch(`${REACT_APP_API_URL}/dashboard-user`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(dashboardUser)
    })

export const updateDashboardUser = (dashboardUserId, dashboardUser) =>
    fetch(`${REACT_APP_API_URL}/dashboard-user/${dashboardUserId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(dashboardUser)
    })

export const deleteDashboardUsers = (dashboardUserIds) =>
    fetch(`${REACT_APP_API_URL}/dashboard-user/batch-delete`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify({ dashboardUserIds })
    })