import {
    UPDATE_USER_DATA,
    UPDATE_AVAILABLE_ORGANIZATIONS,
    UPDATE_SELECTED_ORGANIZATION
} from '../../actionTypes';

const INITIAL_STATE = {
    id: '',
    name: '',
    isAdmin: false,
    availableOrganizations: [],
    selectedOrganization: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_DATA:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                isAdmin: action.payload.isAdmin
            };
        case UPDATE_AVAILABLE_ORGANIZATIONS:
            return {
                ...state,
                availableOrganizations: action.payload,
                selectedOrganization: action.payload[0] ? action.payload[0].id : undefined
            };
        case UPDATE_SELECTED_ORGANIZATION:
            return {
                ...state,
                selectedOrganization: action.payload
            };
        default:
            return state;
    }
}