const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const jwt = () => localStorage.getItem('jwt');

export const getTickets = (options = {}) =>
    fetch(`${REACT_APP_API_URL}/tickets${options.isSimple ? '?is_simple=1' : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const getTicket = (ticketId) =>
    fetch(`${REACT_APP_API_URL}/tickets/${ticketId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const createTicket = (ticket) =>
    fetch(`${REACT_APP_API_URL}/tickets`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(ticket)
    })

export const updateTicket = (ticketId, ticket) =>
    fetch(`${REACT_APP_API_URL}/tickets/${ticketId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(ticket)
    })

export const deleteTickets = (ticketIds) =>
    fetch(`${REACT_APP_API_URL}/tickets/batch-delete`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify({ ticketIds })
    })

export const followUpTicket = (ticketId) => 
    fetch(`${REACT_APP_API_URL}/tickets/${ticketId}/follow-up`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const updateTicketProcess = (ticketId, body) =>
    fetch(`${REACT_APP_API_URL}/tickets/${ticketId}/update-process`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(body)
    })