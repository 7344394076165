import {
    UPDATE_USER_DATA,
    UPDATE_AVAILABLE_ORGANIZATIONS,
    UPDATE_SELECTED_ORGANIZATION
} from '../../actionTypes';

export const updateUserData = (json) => ({
    type: UPDATE_USER_DATA,
    payload: json
});

export const updateAvailableOrganizations = (json) => ({
    type: UPDATE_AVAILABLE_ORGANIZATIONS,
    payload: json
});

export const updateSelectedOrganization = (organizationId) => ({
    type: UPDATE_SELECTED_ORGANIZATION,
    payload: organizationId
});