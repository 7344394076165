import React from 'react';
import {
    Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import PrivateRoute from './routes/PrivateRoute/PrivateRoute';

import LoginPage from './scenes/LoginPage/LoginPage';
import DashboardPage from './scenes/DashboardPage/DashboardPage';
import SummaryPage from './scenes/DashboardPage/scenes/SummaryPage/SummaryPage';
import TicketsPage from './scenes/DashboardPage/scenes/TicketsPage/TicketsPage';

const history = createBrowserHistory();

const dashboardSection = ({ match, ...rest }) => (
    <DashboardPage match={match} {...rest}>
        <Switch>
            <Route exact path={`${match.url}/summary`} component={SummaryPage}/>
            <Route exact path={`${match.url}/tickets`} component={TicketsPage}/>
            <Redirect to={`${match.url}/summary`}/>
        </Switch>
    </DashboardPage>
);

const AppRouter = () => (
    <Router history={history}>
    	<Switch>
            <Route exact path="/" render={() => {
                    const jwt = localStorage.getItem('jwt')

                    if (jwt)
                        return <Redirect to="/dashboard"/>
                    else {
                        localStorage.removeItem('jwt')
                        return <Redirect to="/login"/>
                    }
                }}
            />
            <Route exact path="/login" component={LoginPage}/>
            <PrivateRoute path="/dashboard" component={dashboardSection}/>
			<Redirect to="/"/>
        </Switch>
    </Router>
);

export default AppRouter;