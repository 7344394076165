import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Form, Icon, Input } from 'antd';

import * as actions from '../../services/user/actions';

import logo from './logo.png';
import './LoginPage.scss';

const API_URL = process.env.REACT_APP_API_URL;
const FormItem = Form.Item;

export class LoginPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoggingIn: false,
            errorMessage: ''
        }
    }

    handleSubmit(e) {
        const { form, updateUserData } = this.props
        e.preventDefault();
        this.setState({ isLoggingIn: true, errorMessage: '' });

        form.validateFields((err, values) => {
            if (!err) {
                fetch(`${API_URL}/dashboard-auth/login`, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.error) {
                            this.setState({ errorMessage: json.error.message });
                        } else {
                            updateUserData({
                                id: json.id,
                                name: json.name,
                                isAdmin: json.isAdmin
                            });
                            localStorage.setItem('jwt', json.jwt);
                        }
                        this.setState({ isLoggingIn: false });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isLoggingIn: false });
                    })
            } else {
                this.setState({ isLoggingIn: false });
            }
        });
    }

    render() {
        const { form } = this.props;
        const { isLoggingIn, errorMessage } = this.state;
        const { getFieldDecorator } = form;
        const jwt = localStorage.getItem('jwt');

        if (jwt)
            return <Redirect to="/dashboard"/>;
        else
            return (
                <div className="LoginPage">
                    <div className="LoginPage-container">
                        <div className="LoginPage-logo-container">
                            <img className="LoginPage-logo" src={logo} alt="Informatics Logo"/>
                            <p>Demand-Side Management System</p>
                        </div>
                        <div className="LoginPage-form-container">
                            <p className="error-message">{errorMessage}</p>
                            <Form className="LoginPage-form" onSubmit={(e) => this.handleSubmit(e)}>
                                <FormItem>
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: 'Please input your email!' }],
                                    })(
                                        <Input 
                                            prefix={<Icon type="user" style={{ fontSize: 13 }} />} 
                                            placeholder="Email" 
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input 
                                            prefix={<Icon type="lock" style={{ fontSize: 13 }} />} 
                                            type="password" 
                                            placeholder="Password"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoggingIn}>Log in</Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            );
    }
}

const WrappedLoginPage = Form.create()(LoginPage);
export default connect(null, actions)(WrappedLoginPage);