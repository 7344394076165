import queryStringBuilder from '../../utils/queryStringBuilder';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const jwt = () => localStorage.getItem('jwt');

export const getZones = (options = {}) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones${options.isSimple ? '?is_simple=1' : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const getZone = (zoneId) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones/${zoneId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const getZoneData = (zoneId, queries) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones/${zoneId}/data?${queryStringBuilder(queries)}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const createZone = (zone) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(zone)
    })

export const updateZone = (zoneId, zone) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones/${zoneId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(zone)
    })

export const deleteZones = (zoneIds) =>
    fetch(`${REACT_APP_API_URL}/dashboard-zones/batch-delete`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify({ zoneIds })
    })