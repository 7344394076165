const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const jwt = () => localStorage.getItem('jwt');

export const getOrganizations = (options = {}) =>
    fetch(`${REACT_APP_API_URL}/organization${options.isSimple ? '?is_simple=1' : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const getOrganization = (organizationId) =>
    fetch(`${REACT_APP_API_URL}/organization/${organizationId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        }
    })

export const createOrganization = (organization) =>
    fetch(`${REACT_APP_API_URL}/organization`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(organization)
    })

export const updateOrganization = (organizationId, organization) =>
    fetch(`${REACT_APP_API_URL}/organization/${organizationId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify(organization)
    })

export const deleteOrganizations = (organizationIds) =>
    fetch(`${REACT_APP_API_URL}/organization/batch-delete`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${jwt()}`
        },
        body: JSON.stringify({ organizationIds })
    })