import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Icon, Layout, Menu, Select, Tooltip } from 'antd';
import _ from 'lodash';

import * as actions from '../../services/user/actions';
import * as dashboardUserController from '../../services/api/dashboardUser';
import logo from './logo.png';
import './DashboardPage.scss';

const { Header, Content, Sider } = Layout;
const { Item: MenuItem, SubMenu } = Menu;
const { Option } = Select;

export class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: '',
            selectedSubMenu: []
        };
    }

    getAvailableOrganizations() {
        const { userId, updateAvailableOrganizations } = this.props;

        dashboardUserController.getDashboardUser(userId)
            .then((res) => res.json())
            .then((json) => updateAvailableOrganizations(
                _.map(json.organizations, (organization) => ({
                    id: organization.id,
                    name: organization.name,
                    role: organization.dashboardUserOrganization.role
                }))))
    }

    getMenuStatus() {
        const { location } = this.props;
        const { selectedSubMenu } = this.state;
        const url = location.pathname;
        const subMenuKey = url.substring(0, url.lastIndexOf('/'));
        let newState = { selectedKey: url };

        if (subMenuKey !== '/dashboard')
            newState.selectedSubMenu = _.concat(selectedSubMenu, subMenuKey)

        this.setState(newState);
    }

    handleSubMenuClick({ key, e }) {
        const { selectedSubMenu } = this.state;
        if (selectedSubMenu.includes(key)) {
            _.remove(selectedSubMenu, (selectedKey) => selectedKey === key);
            this.setState({ selectedSubMenu });
        } else {
            this.setState({ selectedSubMenu: _.concat(selectedSubMenu, key) });
        }
    }

    handleLogout(e) {
        const { history } = this.props;
        localStorage.removeItem('jwt');
        history.push('/login');
    }

    componentDidMount() {
        this.getMenuStatus();
        this.getAvailableOrganizations();
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;

        if (prevProps.location.pathname !== location.pathname) {
            this.getMenuStatus();
        }
    }

    renderAdminMenuItem() {
        return (
            <SubMenu key="/dashboard/admin" title="Admin Section" onTitleClick={(obj) => this.handleSubMenuClick(obj)} disabled>
                <MenuItem key="/dashboard/admin/organization">
                    <NavLink to="/dashboard/admin/organization" activeClassName="active-link">
                        <span>Organization</span>
                    </NavLink>
                </MenuItem>
                <MenuItem key="/dashboard/admin/user">
                    <NavLink to="/dashboard/admin/user" activeClassName="active-link">
                        <span>User</span>
                    </NavLink>
                </MenuItem>
                <MenuItem key="/dashboard/admin/config">
                    <NavLink to="/dashboard/admin/config" activeClassName="active-link">
                        <span>Config</span>
                    </NavLink>
                </MenuItem>
            </SubMenu>
        )
    }

    render() {
        const { userName, isAdmin, availableOrganizations, selectedOrganization, updateSelectedOrganization } = this.props;
        const { selectedKey, selectedSubMenu } = this.state;
        const organizationOptions = _.map(availableOrganizations, (organization) => <Option key={organization.id} value={organization.id}>{organization.name}</Option>);

        return (
            <Layout className="DashboardPage">
                <Header className="header">
                    <img className="logo" src={logo} alt="Belimo"/>
                    <div className="left-section">
                        <Select
                            placeholder="Organization"
                            value={selectedOrganization}
                            onChange={(value) => updateSelectedOrganization(value)}
                        >
                            {organizationOptions}
                        </Select>
                    </div>
                    <div className="right-section">
                        <div className="user-section">
                            <div className="username">{userName}</div>
                        </div>
                        <div className="icon-btn-gp">
                            <div className="icon-btn disabled">
                                <Tooltip placement="bottom" title="Setting">
                                    <Icon type="setting" />
                                </Tooltip>
                            </div>
                            <div className="icon-btn">
                                <Tooltip placement="bottom" title="Logout">
                                    <Icon type="logout" onClick={(e) => this.handleLogout(e)}/>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        className="Sider"
                        trigger={null}
                    >
                        <Menu 
                            mode="inline"
                            className="Menu"
                            selectedKeys={[selectedKey]}
                            openKeys={selectedSubMenu}
                        >
                            <MenuItem key="/dashboard/summary">
                                <NavLink to="/dashboard/summary" activeClassName="active-link">
                                    <span>Summary</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key="/dashboard/tickets">
                                <NavLink to="/dashboard/tickets" activeClassName="active-link">
                                    <span>Tickets</span>
                                </NavLink>
                            </MenuItem>
                            { isAdmin && this.renderAdminMenuItem() }
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content>
                            {this.props.children}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
    userName: state.user.name,
    isAdmin: state.user.isAdmin,
    availableOrganizations: state.user.availableOrganizations,
    selectedOrganization: state.user.selectedOrganization
});

export default connect(mapStateToProps, actions)(DashboardPage);