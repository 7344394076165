import React, { Component } from 'react';
import { DatePicker, Modal } from 'antd';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import _ from 'lodash';
import moment from 'moment';
// import randomColor from 'randomcolor';

import * as zoneController from '../../../../../../services/api/zone';
import './ComfortZoneModal.scss';

const { RangePicker } = DatePicker;

export default class ComfortZoneModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            zoneData: [],
            from: moment().startOf('month'),
            to: moment().endOf('month')
        }
    }

    getZoneData() {
        const { selectedZoneId } = this.props;
        const { from, to } = this.state;

        zoneController.getZoneData(selectedZoneId, { from: from.toISOString(), to: to.toISOString() })
            .then(res => res.json())
            .then(json => this.setState({ zoneData: json.data }));
    }

    getOptions() {
        const { zoneData } = this.state;
        let series = [];
        _.forEach(zoneData, (userData) => {
            let coords = _.map(userData.boundary, (data) => [data.temp, data.humidity]);

            series.push({
                type: 'scatter',
                symbolSize: 2,
                itemStyle: {
                    color: 'rgba(238,110,31, 1)'
                },
                data: _.map(userData.data, (data) => [data.temp, data.humidity]),
                animation: false,
                silent: true,
            });
            series.push({
                type: 'custom',
                renderItem: (params, api) => {
                    let points = [];
                    for (let i = 0; i < coords.length; i++) {
                        points.push(api.coord(coords[i]));
                    }

                    return {
                        type: 'polygon',
                        shape: {
                            points: echarts.graphic.clipPointsByRect(points, {
                                x: params.coordSys.x,
                                y: params.coordSys.y,
                                width: params.coordSys.width,
                                height: params.coordSys.height
                            })
                        },
                        style: api.style({
                            fill: 'rgba(238,110,31, 0.03)',
                        }),
                        styleEmphasis: api.style({
                            fill: 'rgba(238,110,31, 0.5)'
                        })
                    };
                },
                animation: false,
                data: coords,
            });
        })

        let options = {
            xAxis: {
                name: 'Temperature(°C)',
                nameLocation: 'middle',
                nameGap: 20,
                scale: true
            },
            yAxis: {
                name: 'Humidity(%)',
                nameLocation: 'middle',
                nameGap: 50,
                scale: true
            },
            series
        };

        return options;
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible } = this.props;
        const { from, to } = this.state;

        if (visible !== prevProps.visible && visible) {
            this.getZoneData();
        }

        if (visible && (!_.isEqual(from, prevState.from) || !_.isEqual(to, prevState.to))) {
            this.getZoneData();
        }
    }

    render() {
        const { selectedZoneName, ...props } = this.props;
        const { from, to } = this.state;

        return (
            <Modal
                className="ComfortZoneModal"
                width="70%"
                title={null}
                footer={null}
                {...props}
            >
                <h2>Comfort Zone Distribution of {selectedZoneName}</h2>
                <div className="date-picker">
                    <RangePicker 
                        placeholder={['From', 'To']}
                        allowClear={false}
                        value={[from, to]}
                        onChange={(dates) => this.setState({ from: dates[0], to: dates[1] })}
                    />
                </div>
                <ReactEcharts
                    style={{ height: '500px'}}
                    notMerge
                    option={this.getOptions()}
                />
            </Modal>
        );
    }
}