import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import * as actions from '../../services/user/actions';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

const API_URL = process.env.REACT_APP_API_URL;

export class PrivateRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isTokenValid: false
        };
    }

    authenticate() {
        const { updateUserData } = this.props;
        const jwt = localStorage.getItem('jwt');

        if (jwt) {
            fetch(`${API_URL}/dashboard-auth/authenticate`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${jwt}`
                    }
                })
                .then((res) => res.json())
                .then((json) => {
                    if (json.error) {
                        localStorage.removeItem('jwt');
                        this.setState({ isLoading: false, isTokenValid: false });
                    } else {
                        updateUserData({
                            id: json.id,
                            name: json.name,
                            isAdmin: json.isAdmin
                        });
                        this.setState({ isLoading: false, isTokenValid: true });
                    }
                })
                .catch((err) => console.log(err))
        } else {
            this.setState({ isLoading: false, isTokenValid: false });
        }
    }

    componentWillMount() {
        this.authenticate();
    }

    componentWillUpdate(nextProps, nextState) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname)
            this.authenticate();
    }

    render() {
        const { component, ...rest } = this.props
        const { isLoading, isTokenValid } = this.state

        if (isLoading)
            return (<LoadingScreen loadingText="Authenticating..."/>);
        else
            return (
                <Route 
                    {...rest }
                    render={ props => isTokenValid ? React.createElement(component, props) : <Redirect to="/login"/> }
                />
            );
    }
}

export default connect(null, actions)(PrivateRoute);